$(document).on("turbolinks:load", function () {
  setTimeout(() => {
    const $ticketPopup = $("#ticketInfo-popup");
    if ($ticketPopup && window.location.pathname === "/tickets/new") {
      $ticketPopup.click();
      const backdrop = document.createElement("div");
      backdrop.classList.add("custom-modal-backdrop");
      document.body.appendChild(backdrop);
    }
  }, 2000);
});

// $(document).on("turbolinks:load", function () {
//   $("#dismiss-button").on("click", function () {
//     const $modalBackdrop = $(".custom-modal-backdrop");
//     if ($modalBackdrop) {
//       $modalBackdrop.remove();
//     }
//   });
// });

$(document).on("turbolinks:load", function () {
  $(".modal").on("hidden.bs.modal", function () {
    const $modalBackdrop = $(".custom-modal-backdrop");
    if ($modalBackdrop) {
      $modalBackdrop.remove();
    }
  });
});
